<template>
    <layout>
        <page title="Monitoring">
            <template v-slot:header-content>
                <div class="form-group form-inline mr-10">
                    <label class="mt-5">FROM </label>
                    <input v-model="form.from_date" @change="getList()" style="width: 250px;" type="date" class="form-control" name="from_date" id="from_date">
                </div>
                <div class="form-group form-inline mr-20">
                    <label class="mt-5">TO </label>
                    <input v-model="form.to_date" @change="getList()" style="width: 250px;" type="date" class="form-control" name="to_date" id="to_date">
                </div>
                <input v-model="search" @keyup="getList()" type="text" class="form-control" placeholder="Search">
            </template>
            <div class="table-div">
                <table class="table table-bordered table-hover table-outer-bordered table-responsive" style="font-size: 12px">
                    <thead>
                        <tr>
                            <th width="280" class="text-center headcol-name border">👤 NAME</th>
                            <th width="120" class="text-center headcol-initials border-top border-bottom">✒️ INITIALS</th>
                            <th width="120" class="text-center headcol-position border-top border-bottom">♟️ POSITION</th>
                            <th v-for="(column, index) in adjustments" :key="index" class="text-center date-col">{{ index }}</th>
                            <th v-if="adjustments == 0" class="text-center date-col">NO DATA AVAILABLE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employee in employees" :key="employee.id">
                            <td class="headcol-name border-bottom border-left" style="font-size: 15px" v-tippy="getEmployeeTP(employee.firstname, employee.lastname, employee.middlename)">
                                {{ employee.lastname }} {{(employee.lastname)?",":"" }} {{ employee.firstname }} {{(employee.middlename)?"(":"" }}{{ employee.middlename }}{{(employee.middlename)?")":"" }}
                            </td>
                            <td class="text-center headcol-initials border-bottom" style="font-size: 15px" :style="{background: employee.color}">{{ employee.initial }}</td>
                            <td class="text-center headcol-position border-bottom" style="font-size: 15px">{{ employee.position }}</td>
                            <td v-for="(column, colIndex) in adjustments" :key="colIndex" class="text-center date-col" style="font-size: 15px">
                                <div v-if="hasItems(column, employee.id)" :key="rowIndex" style="font-size: 15px;" v-tippy="getToolTip(column, employee.id)">
                                    ✔
                                </div>
                            </td>
                            <td v-if="adjustments == 0"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </page>
    </layout>
</template>

<script>
import Page from "../../components/Page";
import * as dayjs from "dayjs";
import { directive } from 'vue-tippy';

export default {
    directives: {
      'v-tippy': directive,
    },
    components: {
        Page,
    },
    data() {
        return {
            employees: [],
            adjustments: [],
            search: '',
            form: {
                from_date: dayjs().startOf('month').format('YYYY-MM-DD'),
                to_date: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList(link = null) {
            let newLink = link || `/api/employee/getEmployees?`;

            Promise.all([
                this.$http.get(`${newLink}search=${this.search}`),
                this.$http.post('/api/inventory/getListByDate', this.form)
            ]).then(result => {
                this.employees = result[0].data.list;
                this.adjustments = result[1].data;
            });
        },

        hasItems(list, employee_id) {
            const item = Array.from(list).filter(row => row.employee_id == employee_id);
            return item.length ? true : false;
        },

        getToolTip(list, employee_id) {
            const item = Array.from(list).filter(row => row.employee_id == employee_id);
            let displayStr = "";

            item.forEach((value) => {
                displayStr += "<b>Item:</b> " + value.name + "<br><b>Quantity</b>: " + value.quantity + "<br><b>Size</b>: " + value.size + "<br><b>Type</b>: <span style='text-transform: uppercase;'>" + value.type + "</span><br><hr>";
            });

            return {
                allowHTML: true,
                content : displayStr,
                theme: 'hwi-theme'};
        },
        getEmployeeTP(firstname, lastname, middlename){
            let displayStr = lastname + ((lastname)?", ":"") + firstname + ((middlename)?" (":"") + middlename + ((middlename)?") ":"");

            return {
                allowHTML: true,
                content : displayStr,
                theme: 'hwi-theme'};
        }
    }
}
</script>

<style scoped>
      td,
      th {
        /* white-space: wrap; */
        height: 55px;
      }
      div.table-div{
        width: calc(100% - 523px);
        overflow: scroll;
        margin-left: 523px;
        /* overflow-y: visible; */
        /* padding: 0; */
      }
      .headcol-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        left: 45px;
        width: 200px;
      }
      .headcol-initials {
        position: absolute;
        left: 244px;
        width: 120px;
      }
      .headcol-position {
        position: absolute;
        left: 363px;
        width: 200px;
      }
</style>